import React from "react"
import { Link } from "gatsby"
import Layout from "components/site-structure/layout"
import SEOComponent from "components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEOComponent title="404: Not found" description="404: Not found" />
    <h1>Diese Seite gibt es nicht (mehr)</h1>
    <p>
      Möchtest Du Mölkky spielen?
      <Link to={"/spielen/"}>Hier geht's zum Mölkky Online Scoreboard</Link>
    </p>
  </Layout>
)

export default NotFoundPage
